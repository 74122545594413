
import { Button, IconButton, Card, DataList, Table, Switch } from '@/ui/index';
import { defineComponent } from 'vue';
import { ProjectController, TimeEntryController } from '@/controller/';
import store from '@/store/index';
import { Project } from '@/model';
import { Converter } from "@/utils";


export default defineComponent({
    name: "projectDetail",
    components: { Button, IconButton, Card, DataList, Table, Switch },
    data(){
        return{
            store,
            project:{} as Project,
            timeTables: [] as Array<any>,
            lastActivities: [] as Array<any>,
            projectTimeEntries: [] as Array<any>,
            worksTableData:{
                title: this.$t('label.hours'),
                icon: store.getters.svgIcons.timeTable, 
                thFields: [
                    {title: this.$t("label.date"), property: "startDay", type: "string", convert: "convertTimeStampToDate" },
                    { title: this.$t('label.employee'), property: "employee.fullName", type: "string"},
                    { title: this.$t("label.work"), property:"work.title", type: "string"},
                    { title: this.$t("label.hours"), property:"timeGone", type: "date", convert: "convertSecondsToTime"}],
            }
        }
    },
    computed:{
        getProfileImageSrc() {
            return ''
        }, 
        getTimeByActivity() {
            let works = [] as Array<any>;

            this.projectTimeEntries.forEach((entry : any)=> {
                if(!works.find((work : any) => entry.work.uid == work.uid)) {
                    works.push({uid: entry.work.uid, title: entry.work.title, time: 0});
                }

                works.forEach((work : any, index: number) => {
                    if(entry.work.uid == work.uid && entry.getTimeGone()) {
                        works[index].time += entry.getTimeGone();
                    }
                } )
            });
            return works;
        },
        getTimeByEmployee() {
            let employees = [] as Array<any>;

            this.projectTimeEntries.forEach((entry : any)=> {
                if(!employees.find((employee : any) => entry.employee.uid == employee.uid)) {
                    employees.push({uid: entry.employee.uid, name: entry.employee.fullName, time: 0});
                }

                employees.forEach((employee : any, index: number) => {
                    if(entry.employee.uid == employee.uid && entry.getTimeGone()) {
                        employees[index].time += entry.getTimeGone();
                    }
                } )
            });
            console.log('getTimeByEmployee ', employees)
            return employees;
        },
        totalProjectTime() {
            let sum = 0
            this.projectTimeEntries.forEach((entry : any)=> sum += entry.getTimeGone());
            return sum
        },
        totalTimeByActivity() {
            let total = 0;
            this.getTimeByActivity.forEach((item:any) => {
                total += item.time;
            });
            return total;
        },
        totalTimeByEmployee() {
            let total = 0;
            this.getTimeByEmployee.forEach((item:any) => {
                total += item.time;
            });
            return total;
        },        
        preparedTableData() {
            let items : any = [];
            if (this.projectTimeEntries) {
                items = [...this.projectTimeEntries];
                items.forEach((item:any, index:number) => {
                    items[index].editTimeString = this.convertSecondsToHours(item.editTime);
                    items[index].tstampString = this.convertTimeStampToDate(item.tstamp);
                })
            }
            return items;
        }
    },
    created(){
        if (this.$route.params.id) {
            this.handleGetProject(this.$route.params.id);
            this.fetchProjectEntries(this.$route.params.id);
        }
    },
    methods:{
        handleGoBack(){
            this.$router.go(-1)
        },
        convertSecondsToHours(seconds : number){
            return Converter.convertSecondsToTime(seconds);
        },
        convertTimeStampToDate(tstamp:any) {
            return Converter.convertTimeStampToDate(tstamp);
        },
        handleEdit(){
            this.$router.push('/administration/project/form/' + this.$route.params.id);
        },
        async handleToggleActiveProject() {
            const postData = {
                'uid': this.project.uid,
                'status': !this.project.active
            }
            const res = await ProjectController.toggleProject(postData);
            if (res.project) {
                this.project = res.project
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        convertWorkTimeStamp(start:any, stop:any, totaltime:any) {
            let time = 0;
            if(start > 0 && stop > 0) {
                time = stop -start;
            } else {
                time = totaltime;
            }
            var date = new Date(time * 1000);
            return date.getHours() + ':' + date.getMinutes()+ ' h'
        },
        handleDeactivate() {
            console.log('handleDeactivate')
        },
        async handleGetProject(uid:any) {
            const res = await ProjectController.getProjectById(uid);
            if (!res.error) {
                this.project = res.project;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async fetchProjectEntries(uid:any){
            const res = await TimeEntryController.fetchProjectEntries(uid);
            if(!res.error){
                this.projectTimeEntries = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async handleDelete() {
            const res = await ProjectController.deleteProject(this.project.uid)
            if (!res.error) {
                this.$notify(this.$t("success.projectDeleted"), { position: "top" });
                this.$router.push("/administration/projects")
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        handleIconButtonClicked(){
            console.log('handleIconButtonClicked')
        },
    }
})
